import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import _, { set } from 'lodash';
import * as React from 'react';
import InputFile from '../../uploader/InputForm.js';
import '../dashboard.css'
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const UploadForm = (props) => {
    const { algoList } = props;
    const [imageDesc, setImageDesc] = React.useState("");
    const [tags, setTags] = React.useState("");
    const [tissueType, setTissueType] = React.useState('');
    // const [imageDropdownName, setImageDropdownName] = React.useState([]);
    // const [imageSizeValue, setImageSizeValue] = React.useState([]);
    /* const imageSizeDropdown = [
        {   
            id: '001',
            sizeName: '1.2mm ✖️ 1.2mm',
            sizeValue: '1.2 , 1.2'
        }
    ];*/
    const tissueTypeDropdown = [
        {
            id: '001',
            tissueType: 'Rat Fatty Liver'
        }
    ]

    React.useEffect(() => {
        if (!!algoList && algoList.length > 0) {
            const algos = algoList;
            let names = [];
            _.forEach(algos, (algo) => {
                names.push(algo.algo_name)
            })
        }
    }, [algoList]);

    /*const handleChange = (event: SelectChangeEvent) => { // temp commented to not get dropdown size values
        setImageDropdownName(event.target.value.sizeName);
        setImageSizeValue(event.target.value.sizeValue);
    };*/

    const handleTissueTypeChange = (event: SelectChangeEvent) => {
        setTissueType(event.target.value);
    };

    return (
        <Box component="fieldset" className='fieldset'
            sx={{
                '& .MuiTextField-root': { m: 1, width: '100%' },
                paddingLeft: '10px',
            }}
            noValidate
            autoComplete="off"
        >
            <Box className='legend' component="legend">Image Information</Box>

            <div className='w95'>
                <Typography variant='h6' className='headtxtcss fntb141'>
                    Description :
                </Typography>
                <TextField
                    id="outlined-disabled"
                    label="Description"
                    onChange={(event) => setImageDesc(event.target.value)}
                />

                <Typography variant='h6' className='headtxtcss fntb141'>
                    Tag :
                </Typography>
                <TextField
                    id="outlined-multiline-static"
                    label="Tags (X, Y..)"
                    multiline
                    onChange={(event) => setTags(event.target.value)}
                />
                <div className='wrapsec12'>
                    <Typography variant='h6' className='headtxtcss fntb14'>
                        Tissue Type :
                    </Typography>
                    <FormControl className='selectbox'>
                        <Select
                            labelId='tissue-type-select-label'
                            id='tissue-type-select'
                            value={tissueType}
                            onChange={handleTissueTypeChange}
                            className='wh100'
                            displayEmpty
                            renderValue={tissueType => {
                                if (tissueType.length === 0) {
                                    return (
                                        <em style={{ fontStyle: 'normal' }}>Select Tissue Type</em>
                                    )
                                }
                                return tissueType
                            }}
                        >
                            {tissueTypeDropdown && tissueTypeDropdown.length > 0 && tissueTypeDropdown.map((item) => (
                                <MenuItem value={item.tissueType} key={item.id}>{item.tissueType}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                {/*<div className='wrapsec12'>
                    <Typography variant='h6' className='headtxtcss fntb14'>
                        Size :
                    </Typography>
                    <FormControl className='selectbox'>
                        <Select
                        labelId='demo-simple-select-helper-label'
                        id='demo-simple-select-helper'
                        value={imageDropdownName}
                        onChange={data => handleChange(data)}
                        className='wh100'
                        displayEmpty
                        renderValue={imageDropdownName => {
                            if (imageDropdownName.length === 0) {
                                return (
                                    <em style={{ fontStyle: 'normal' }}>Select Image Size</em>
                                )
                                }
                                return imageDropdownName
                            }}
                        >
                            {imageSizeDropdown && imageSizeDropdown.length > 0 && imageSizeDropdown.map((item) => (
                                <MenuItem value={item} key={item.id}>{item.sizeName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>*/}
                <InputFile 
                    handleCallBack={props.handleCallBack}
                    imageDesc={imageDesc}
                    tags={tags}
                    imageSizeValue={'1.2 , 1.2'}
                    tissueType={tissueType}
                />
            </div>
        </Box>
    );
}

export default UploadForm
